import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import { toast } from "sonner";
import { formatEther } from "viem";
// import { updateRevShareMerkleData } from "../../firebase/FirebaseConfig";
import { generateMerkleRoot } from "../../lib/utils";

const ReviewAndConfirm = ({
  shares,
  ethSharePool,
  setActiveStep,
  setShares,
  setEthPerTokenRate,
  setRoot,
}) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const proceedClickHandler = async () => {
    setIsBtnLoading(true);

    try {
      // TODO URGENT: Uncomment this once documentation is done to restore proper functionality
      // await updateRevShareMerkleData(shares);
      setRoot(generateMerkleRoot(shares));
      setActiveStep(3);
    } catch (e) {
      console.log(e);
      toast.error("An error occurred writing to database");
    }

    setIsBtnLoading(false);
  };

  const goBackClickHandler = () => {
    setActiveStep(1);
    setShares([]);
    setEthPerTokenRate("");
    setRoot("");
  };

  return (
    <div>
      <div className="flex flex-col gap-2">
        <span className="text-light text-2xl font-semibold">
          Review and Confirm
        </span>
        <p className="text-darkgray font-normal">
          Review the generated revenue shares in accordance to their held TOKEN
          to ETH reward rate per token in order to confirm and proceed
        </p>
      </div>

      <div className="flex flex-col gap-4 mt-10">
        <div className="flex justify-between items-center">
          <span className="text-light text-lg font-semibold">Depositor</span>
          <span className="text-light text-lg font-semibold">Shares</span>
        </div>
        {shares.map((share, index) => (
          <div
            key={index}
            className="flex justify-between items-center border-b border-gray-800 py-2"
          >
            <span className="text-light text-lg font-normal">{share.id}</span>
            <span className="text-light text-lg font-normal">
              {Number(formatEther(share.gngAmount)).toFixed(0)} PNGVN
            </span>
            <span className="text-light text-lg font-normal">
              {Number(formatEther(share.share)).toFixed(4)} Eth
            </span>
          </div>
        ))}
      </div>

      <div className="mt-8 text-darkgray">
        A total of{" "}
        <span className="font-semibold text-white px-1">{shares.length}</span>{" "}
        depositors to be eligible to claim a total of{" "}
        <span className="font-semibold text-white px-2">
          {Number(formatEther(ethSharePool)).toFixed(4)} Eth
        </span>{" "}
        in revenue shares
      </div>

      <div className="flex justify-between mt-10">
        <Button onClick={goBackClickHandler} className="bg-gold">
          Back
        </Button>
        <Button
          onClick={proceedClickHandler}
          className="bg-gold"
          disabled={isBtnLoading}
        >
          {isBtnLoading
            ? "Writing to database..."
            : "Proceed and write to database"}
        </Button>
      </div>
    </div>
  );
};

export default ReviewAndConfirm;
